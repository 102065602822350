@import "../app/App.scss";

.documentHeader {
  padding: 0.9em 2em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #f1f1f1;
  margin-top: -2em;

  .documentInfoCard {
    display: grid;
    margin: 0 2em;
  }

  .compareLink {
    position: absolute;
    right: 10px;
  }

  .viewer-button-wrapper {
    flex-grow: 1;

    .open-viewer:disabled {
      background-color: lightgray;
      color: darkgray;
      cursor: default;
      padding: 0.5em 2.2em 0.7em;
      margin: 0.4em 0.7em 0.4em 0;
    }

    .open-viewer {
      float: right;
      background-color: gray;
      font-size: 1em;
      border: 1px solid var(--foregroundLightGrey);
      padding: 0.5em 2.2em 0.7em;
      margin: 0.4em 0.7em 0.4em 0;
      border-radius: 6px;
      color: white;
      cursor: pointer;
      transition: all 0.03s ease-in;
    }
  }

  .popup-button-wrapper {
    .open-popup {
      float: right;
      background-color: #bd5649;
      font-size: 1em;
      border: 1px solid var(--foregroundLightGrey);
      padding: 0.5em 2.2em 0.7em;
      margin: 0.4em 0.7em 0.4em 0;
      border-radius: 6px;
      color: white;
      cursor: pointer;
      transition: all 0.03s ease-in;
    }
  }
}

.popup-dialog {
  position: fixed;
  width: 30%;
  border: 1px solid darkgray;
  z-index: 3;
  background-color: var(--backgroundLightGrey);

  &.hidden {
    display: none;
  }

  .handle {
    background-color: var(--backgroundDark);
    height: 1.5em;
    cursor: move;
    text-align: right;
    display: flex;
    flex-direction: row;
    padding-right: 0.5em;
    align-items: baseline;
    color: var(--foregroundAccentLight);

    span {
      flex-grow: 1;
      cursor: pointer;
    }
  }

  .content {
    color: var(--textLevel1);
    max-height: 700px;
    overflow: auto;
    text-align: left;
  }

}

.name {
  font-size: 0.8em;
}

.value {
  font-size: 0.9em;
}

.documentMain {
  .stickyColumn {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: start;

    .stickyColumn {
      position: sticky;
      top: 0;
    }
  }

  .content {
    overflow-x: auto;
  }
}

.docNavHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;

  span.noValue {
    color: var(--foregroundLightGrey);
  }
}

.iifViewer {
  height: 100%;

  .dragon {
    padding-top: 2em;
    top: 0;
    position: sticky;
  }

  #openseadragon1 {
    height: 800px;
  }
}

.sidebar-Inactive {
  grid-template-columns: 98% 2%;

  .expand {
    width: 100%;
    padding: 0;
    margin: 0;
    right: 1em;
    position: relative;
  }
}

.sidebar-active {
  grid-template-columns: 85% 15%;
}

.document-pages-image {
  display: grid;
  grid-template-columns: 50% 50%;
}

.document-pages {
  display: grid;
  grid-template-columns: 100%;
}

.document-image {
  display: grid;
  grid-template-columns: 100%;
}

.loader {
  margin-top: 5em;
  margin-left: 50%;
}

.documentViewer {
  display: grid;
  padding-left: 20px;

  table,
  .complete-document {
    width: 100%;
  }

  table {
    table-layout: fixed;
  }
}

@keyframes highlight {
  from {
    box-shadow: -1em -1em 0.4em #ede8d5;
  }

  to {
    box-shadow: -1em -1em 0.4em transparent;
  }
}

.popup-attribute-table {
  width: 100%;

  >tbody>tr>td>div {
    overflow: auto;
  }
}

.html-image-collection-part {
  width: 100%;
  overflow: auto;
}

@keyframes highlight {
  0% {
    background-color: #f1f1f1;
  }

  50% {
    background-color: darkorange;
  }

  100% {
    background-color: #f1f1f1;
  }
}

.monodi-comment-block {
  padding: 0.5em;
  margin: 0em 0.5em 2em 0.5em;
  background-color: #f1f1f1;
  border: 1px solid #d1d1d1;

  h2 {
    margin-top: 0;
  }

  &.active {
    animation: highlight 1s ease-in-out;
  }

  .part {
    margin: 1rem 0rem;
  }
}

.paratext-table {
  border-collapse: collapse;

  td {
    padding: 0.5em;
  }
}
