.iifViewer {

  .dragonToolbar {
    border: darkgray;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 0 0 0 1rem;
    background-color: white;

    .dragon-note {
      padding: 5px;
    }

    .buttons {
      .dragonNavButton {
        background-color: lightgray;
        font-size: 1em;
        border: 1px solid var(--foregroundLightGrey);
        padding: 0.3em 2.2em 0.3em;
        margin: 0.4em 0.2em 0.4em 0;
        border-radius: 6px;
        color: black;
        cursor: pointer;
      }

      .dragonNavButton:disabled {
        background-color: lightgray;
        font-size: 1em;
        border: 1px solid var(--foregroundLightGrey);
        padding: 0.3em 2.2em 0.3em;
        margin: 0.4em 0.2em 0.4em 0;
        border-radius: 6px;
        color: darkgray;
        cursor: default;
      }

      .imageLabel {
        width: 8rem;
        text-align: center;
        color: black;
        border: none;
        font-size: 1em;
        background-color: unset;
      }
    }

    .error-message {
      text-align: center;

      .empty {
        text-align: center;
        color: darkred;
        font-size: medium;
      }
    }

  }


  #openseadragon1 {
    border: darkgray;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    padding: 1rem;
    background-color: white;
  }


}
