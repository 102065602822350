.booklet {
  .merge-loader {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;


    .content {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 20rem;
      align-items: center;

      background-color: white;
      padding: 0.5 2em 2em 0.5em;
      text-align: center;
      border-radius: 0.3rem;

      .title {
        font-size: 2em;
        text-align: center;
        border-bottom: 1px solid black;
      }

      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      button {
        background-color: #bd5649;
        font-size: 1em;
        border: 1px solid var(--foregroundLightGrey);
        padding: 0.5em 2.2em 0.7em;
        margin: 0.4em 0.7em 0.4em 0;
        border-radius: 6px;
        color: white;
        cursor: pointer;
        transition: all 0.03s ease-in;

        &.disabled {
          background-color: var(--foregroundLightGrey) !important;
        }
      }
    }
  }

  .top-tool {
    margin: 3em;
    text-align: right;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    justify-content: flex-end;
    align-items: center;

    button {
      background-color: #bd5649;
      font-size: 1em;
      border: 1px solid var(--foregroundLightGrey);
      padding: 0.5em 2.2em 0.7em;
      border-radius: 6px;
      color: white;
      cursor: pointer;
      transition: all 0.03s ease-in;

      &.disabled {
        background-color: var(--foregroundLightGrey) !important;
      }
    }

    .settings-icon {
      cursor: pointer;

      &:hover {
        color: lightgray;
      }
    }
  }

  .doc-count {
    display: grid;
    text-align: center;
  }

  .search-output-table {

    .drag {
      cursor: grab;

      svg {
        margin-top: 50%;
      }
    }

    width: 100%;
    margin: 1em 0;
    border-spacing: 0;
    border-collapse: collapse;
    font-size: 1em;

    tr {
      padding: 1em 0;

      &.drop-zone {
        background-color: lightblue;
      }
    }

    td:first-child,
    th:first-child {
      border-left: none !important;
    }

    td:last-child,
    th:last-child {
      border-right: none !important;
    }

    td {
      padding: 0.8em 2.5em;
      border: 1px solid #d5d5d5;
      max-width: 20em;
    }

    th {
      user-select: none;
      cursor: pointer;
      padding: 0.8em 2.5em;
      border-top: none !important;
      font-weight: bolder;
      color: #676767;
      border: 1px solid #d5d5d5;

      svg.hide {
        visibility: hidden;
      }
    }

    .openLink {
      color: #6a21a0;
      text-decoration: underline;
      cursor: pointer;
    }

    .tool {
      cursor: pointer;
    }
  }

  .dialog-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    display: flex;
    top: 0;
  }

  .cover-dialog {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    grid-template-columns: min(60rem, 90%);
    grid-template-rows: 90%;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    z-index: 1;

    >div {
      background-color: white;
      border-radius: 6px;
    }
  }

  .settings-dialog {
    position: fixed;
    background-color: white;
    border-radius: 6px;
    z-index: 99;
    border: 1px solid black;

    top: 50%;
    left: 50%;
    max-width: 90vw;
    max-height: 90vh;
    transform: translate(-50%, -50%);
    overflow: auto;

    .close-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .main {
      overflow: auto;
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .page-setup {

        .page-inputs {

          label {
            margin-left: 10px;
          }

          display: grid;
          grid-template-columns: 30% 1fr;
          justify-items: left;

          .zoom-slider {
            display: flex;
            align-items: center;
          }
        }

        .page-selection {
          display: flex;

          label {
            margin-top: 10px;
          }

          .select-page {
            margin-left: 20px;
          }

          .restore-default {
            margin-left: 20px;
            margin-top: 10px;
            cursor: pointer;
          }

          padding: 20px;
        }
      }

      .padding-setup {
        padding-top: 20px;
        margin-top: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-top: 1px solid black;

        .padding-inputs {
          margin-top: 10px;
          display: grid;
          grid-template-columns: auto 10% auto 10%;

          label {
            margin-left: 10px;
          }
        }
      }

      .others-setup {
        padding-top: 20px;
        display: grid;
        border-top: 1px solid black;

        .comments-inputs {
          margin-top: 10px;
          display: grid;
          grid-template-columns: 30% 10%;
        }
      }
    }

    hr {
      width: 100%;
      margin: 2rem 0;
      justify-self: center;
      box-sizing: border-box;
    }

    .area {
      display: grid;
      grid-template-columns: repeat(4, auto);
      column-gap: 1rem;
      row-gap: 0.3rem;
      justify-content: start;

      *:nth-child(2n) {
        margin-left: 2rem;
      }

      *:nth-child(2n+1) {
        justify-self: start;
      }

      .area-title {
        grid-column: 1 / span 4;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

    }

    .area.single-column {
      grid-template-columns: repeat(2, auto);

      .area-title {
        grid-column: 1 / span 2;
      }
    }


    .buttons {
      text-align: center;
      padding: 20px;

      button {
        background-color: #bd5649;
        font-size: 1em;
        border: 1px solid var(--foregroundLightGrey);
        padding: 0.5em 2.2em 0.7em;
        margin: 0.4em 0.7em 0.4em 0;
        border-radius: 6px;
        color: white;
        cursor: pointer;
      }
    }
  }
}

.settings-titel {
  font-weight: bold;
}
