.pdf-pagination {
  display: flex;
  justify-content: center;
  button, span {
    margin: 0.5em;
  }
  button:disabled { visibility: hidden; }
}

.pdfviewer {
  mark.search-result {
    color: transparent;
    opacity: 0.3;
  }
}
