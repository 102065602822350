.component_popup {
  position: fixed;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  z-index: 1000;

  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.5);

  background-color: white;

  display: none;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);

  &--open {
    display: grid;
  }

  &__header {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;

    background-color: #ede8d5;
    border-bottom: 1px solid #d3cbb8;
  }

  &__title {
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
    user-select: none;
  }

  &__close {
    padding: 0.5rem;
    user-select: none;
    cursor: pointer;
  }

  &__body {
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 0.5rem;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 999;

    &--moving {
      display: block;
    }
  }

  &__lower-right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    user-select: none;
    cursor: nwse-resize;
    overflow: hidden;

    >div {
      width: 100%;
      height: 100%;
      transform: translate(50%, 50%) rotate(-45deg);

      z-index: -1;

      background: repeating-linear-gradient(0deg,
          rgba(0, 0, 0, 0.4) 0px 2px,
          transparent 2px 4px);
    }
  }
}
