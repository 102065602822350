@import "../app/App.scss";

.documentRight {
  padding-left: 1em;
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: #f1f1f1;
  display: grid;
  grid-template-columns: 8% auto;

  .sticky {
    padding-top: 60px;
    position: sticky;
    top: 0;
  }

  .documentRight-info {
    padding-left: 5px;
    padding-top: 60px;
    position: sticky;
    top: 0;

    table {
      table-layout: fixed;
      position: sticky;
      top: 0;
      padding-top: 60px;

      .entityLink {
        color: #0000EE;
        background-color: transparent;
        border: none;
        padding: none;
        text-decoration: underline;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }

    .short-link-item {
      padding-top: 20px;
    }

    .short-link-text {
      display: flex;
      max-height: 30px;

      input {
        max-width: 70%;
      }

      .short-link-copy {
        max-width: 20%;
        height: 32px;
        padding: unset;
        margin-top: -1px;

        .icon-tabler-clipboard {
          max-width: 20px;
        }
      }

    }
  }

  .documentRight-minimize {
    font-size: xx-large;
    cursor: pointer;
    height: 100%;
    border: 2px solid #eeecec;
    border-radius: 5px;
    background: #eeecec;

    svg {
      position: sticky;
      top: 50%;
    }
  }

  .documentRight-maximize {
    font-size: xx-large;
    cursor: pointer;
    right: 1rem;
    position: relative;
    height: 100%;
    width: 20px;

    svg {
      position: sticky;
      top: 50%;
    }
  }
}
